<template>
  <v-container fluid class="pa-0">
    <v-text-field
      :label="$t('mqtt.meta.fields.publishTopic')"
      v-model="localValue.publishTopic"
      :hint="$t('mqtt.meta.fields.publishTopicHint')"
    />
    <v-container fluid v-if="nodeType != 0">
      <div class="d-flex justify-space-between align-center">
        <div>
          <h4 class="text-left">{{ $t("mqtt.topicCreateTitle") }}</h4>
          <p class="text-left text--disabled outlined" style="max-width: 80%">
            {{ $t("mqtt.topicDescription") }}
          </p>
        </div>
        <v-btn
          @click="openDialog"
          outlined
          color="primary"
          :disabled="localValue.brokerId == ''"
        >
          <v-icon>mdi-plus</v-icon> {{ $t("mqtt.topicAdd") }}
        </v-btn>
      </div>
      <div class="topic-container">
        <div class="topic-title-box"><h4>Topics</h4></div>
        <div class="topic-list">
          <div
            class="topic-item d-flex justify-space-between align-center"
            v-for="(topic, i) in localValue.topics"
            :key="topic.topic"
          >
            <h5 class="text-left">{{ topic.topic }}</h5>
            <div class="topic-actions">
              <v-btn @click="editTopic(i, topic)" color="primary" icon outlined>
                <v-icon>mdi-pen</v-icon>
              </v-btn>
            </div>
          </div>
        </div>
      </div>
    </v-container>

    <v-dialog v-model="dialog" max-width="700" style="min-height: 500px">
      <v-card>
        <v-card-title>{{ $t("mqtt.createTopicTitle") }}</v-card-title>
        <v-card-text>
          <h5 class="text-bold">Node ID:</h5>
          <h5>{{ this.deveui }}</h5>
        </v-card-text>
        <v-card-text>
          <v-text-field
            v-model="topic"
            :label="$t('mqtt.meta.fields.topic')"
            class="px-2"
          />
          <codemirror
            ref="cm"
            v-if="topic != null && topic != ''"
            class="disabled"
            v-model="decoder"
            style="height: auto"
            @focus="
              () => {
                if (decoder == decoderPlaceholder) decoder = defaultDecoder;
              }
            "
            :options="{
              tabSize: 4,
              mode: 'javascript',
              theme: 'monokai',
              lineNumbers: false,
              line: false,
            }"
          />
        </v-card-text>
        <v-card-text v-if="!topicValid">
          <h4
            class="error text-left pa-3"
            style="color: white; border-radius: 5px"
          >
            {{ $t("mqtt.topicAlreadyExists") }}
          </h4>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="closeDialog" color="primary" text>{{
            $t("common.cancel")
          }}</v-btn>
          <v-btn v-if="isEditTopic" @click="deleteTopic" color="error">{{
            $t("common.delete")
          }}</v-btn>
          <v-btn :disabled="!topicValid" @click="save" color="primary">{{
            $t("common.save")
          }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { codemirror } from "vue-codemirror";
import "codemirror/mode/javascript/javascript.js";
import "codemirror/lib/codemirror.css";
import "codemirror/theme/monokai.css";

export default {
  components: { codemirror },
  props: {
    nodeType: {
      type: Number,
      default: 0,
    },

    deveui: {
      type: String,
    },

    broker: {
      type: String,
    },

    edit: {
      type: Boolean,
      default: false,
    },

    value: {
      type: Object,
      default: () => {
        return {
          publishTopic: "",
          brokerId: "",
          topics: [],
        };
      },
    },
  },

  data() {
    return {
      dialog: false,
      isEditTopic: false,
      topicIndex: null,
      topic: "",
      decoderPlaceholder: "function Decode(topic, payload) {}",
      defaultDecoder: `function Decode(topic, payload) {
  var json = JSON.parse(payload);

  return [
    {
      deveui: "deveui-to-add-value-to", 
      key: "temp", 
      value: json.temprature, 
      timestamp: new Date()
    }
  ];
}`,

      decoder: "",
    };
  },

  computed: {
    localValue: {
      get() {
        return this.value;
      },

      set(val) {
        this.$emit("input", val);
      },
    },

    topicValid() {
      if (this.localValue.publishTopic == this.topic) return false;
      if (this.isEditTopic)
        return !this.localValue.topics
          .filter((e, i) => this.topicIndex != i)
          .map((d) => d.topic)
          .includes(this.topic);

      return !this.localValue.topics.map((d) => d.topic).includes(this.topic);
    },
  },

  methods: {
    editTopic(i, topic) {
      this.topic = topic.topic;
      this.decoder = topic.decoder;
      this.topicIndex = i;

      this.isEditTopic = true;
      this.dialog = true;
    },

    openDialog() {
      this.dialog = true;
    },

    closeDialog() {
      this.dialog = false;
      this.topicIndex = null;
      this.isEditTopic = false;

      this.$emit("input", this.localValue);
    },

    save() {
      if (this.isEditTopic && this.topicIndex != null)
        this.localValue.topics[this.topicIndex] = {
          topic: this.topic,
          decoder: this.decoder,
        };
      else {
        this.localValue.topics.push({
          topic: this.topic,
          decoder: this.decoder,
        });

        this.topic = "";
        this.decoder = this.defaultDecoder;
      }

      this.$emit("input", this.localValue);
      this.dialog = false;
      this.isEditTopic = false;

      this.decoder = this.decoderPlaceholder;
      this.topic = "";
    },

    deleteTopic() {
      this.dialog = false;
      if (this.topicIndex != null)
        this.localValue.topics.splice(this.topicIndex, 1);

      this.$emit("input", this.localValue);
    },
  },

  watch: {
    broker(val) {
      this.localValue.brokerId = val;
    },

    deveui(val) {
      this.localValue.deveui = val;
    },
  },

  created() {
    if (this.deveui != null && this.deveui != "")
      this.localValue.deveui = this.deveui;
    if (this.broker != null && this.broker != "")
      this.localValue.brokerId = this.broker;

    if (this.localValue.decoder != null && this.localValue.decoder != "")
      this.decoder = this.localValue.decoder;
    else this.decoder = this.decoderPlaceholder;
  },
};
</script>

<style lang="scss">
.topic-container {
  width: 100%;
  border: 2px solid #e9e9e9;

  .topic-title-box {
    width: 100%;
    background: #e9e9e9;
    color: black;
    padding: 0.7rem;
    text-align: left;
  }

  .topic-list {
    min-height: 140px;
    width: 100%;

    .topic-item {
      background: white;
      color: black;
      border-bottom: 3px solid #e9e9e9;
      padding: 1rem;
      width: 100%;
    }
  }
}
</style>